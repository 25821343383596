import {
	IEventAggregationService,
	ISubscriber
} from "../../../../interfaces/event-aggregation-service";
import { ISession, ISessionService, SessionCreatedEvent } from "../../../../interfaces/session-management";

declare const Rollbar;

export class RollbarIdentitySubscriber implements ISubscriber<any> {

	constructor(
		private eventAggregationService: IEventAggregationService
	) {

	}

	public async notify(event: SessionCreatedEvent | ISessionService): Promise<void> {
		if (event instanceof SessionCreatedEvent) {
			return this.initializeRollbarIdentity(event.session);
		}

		const session = await event.getSession();

		if (session === null) {
			this.eventAggregationService.subscribeToSessionCreatedEvent(this);
			return;
		}

		this.initializeRollbarIdentity(session);
	}

	private initializeRollbarIdentity(session: ISession): void {
		if (typeof Rollbar === 'undefined') {
			return;
		}

		Rollbar.configure({
			payload: {
				person: {
					id: session.getUser().identityId
				}
			}
		} as any);
	}

}
