import { WebsocketServiceReadyEvent } from "../../interfaces/event-aggregation-service/events/websocket-service-ready-event";
import {ISessionService} from '../../interfaces/session-management';
import {ITokenBasedSession} from '../../interfaces/session-management';
import {CallbackSubscriber} from './callback-subscriber';
import {IEventAggregationService} from '../../interfaces/event-aggregation-service';
import {WebSocketGatewaySubscriber} from './web-socket-gateway-subscriber';
import {IWebSocketGateway} from './web-socket-gateway.interface';
import { RefreshTokenError } from "../session-management/domain/errors/refresh-token-error";

export class WebSocketGateway implements IWebSocketGateway {

	private sessionService: ISessionService;
	private eventAggregationService: IEventAggregationService;
	private socket: WebSocket;

	private subscribers: Array<WebSocketGatewaySubscriber<any>> = [];

	constructor(
		public readonly wssEndpoint: string,
	) { }

	public set EventAggregationService(eventAggregationService: IEventAggregationService) {
		this.eventAggregationService = eventAggregationService;
	}

	public async initialize(sessionService: ISessionService) {
		this.eventAggregationService.publishTo(WebsocketServiceReadyEvent.EventType, new WebsocketServiceReadyEvent(this.eventAggregationService));
		this.sessionService = sessionService;

		if (await this.isUserLoggedIn()) {
			await this.connect();
		}

		this.addEventAggregationEventListeners();
	}

	public async connect() {
		try {
			const accessToken = await this.getAccessToken();
			const url = window.location && window.location.href ? encodeURIComponent(window.location.href) : '';
			this.socket = new WebSocket(`${this.wssEndpoint}?token=${accessToken}&url=${url}`);
			this.addWebsocketEventListeners();
		}
		catch (e) {
			if (! (e instanceof RefreshTokenError)) {
				throw e;
			}
			this.eventAggregationService.publishSessionDestroyedEvent();
		}
	}

	public addSubscriber(subscriber: WebSocketGatewaySubscriber<any>) {

		this.subscribers.push(subscriber);
	}

	private async isUserLoggedIn(): Promise<boolean> {
		const session = await this.sessionService.getSession();
		return session !== null;
	}

	private addEventAggregationEventListeners() {
		this.eventAggregationService.subscribeToSessionCreatedEvent(new CallbackSubscriber(() => {
			return this.connect();
		}));
	}

	private addWebsocketEventListeners() {
		this.socket.addEventListener('message', (message) => {
			this.onMessage(JSON.parse(message.data));
		});
		this.socket.addEventListener('close', () => {
		   this.reconnect();
		});
	}

	private reconnect() {
		setTimeout(() => {
			return this.connect();
		}, 5000);
	}

	private async getAccessToken() {
		const session = await this.sessionService.getSession() as ITokenBasedSession;
		return session.getAccessToken();
	}

	private onMessage(message: any) {
		this.subscribers
			.filter((s) => s.canHandle(message.eventType))
			.forEach((s) => s.handle(message));
	}
}
