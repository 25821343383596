import config from 'config';
import { ISessionService } from '../../interfaces';
import FetchWrapper from '../../libs/FetchWrapper';
import { CallbackSubscriber } from '../event-aggregation-service/callback-subscriber';
import { EventAggregationService } from '../event-aggregation-service/event-aggregation-service';
import { WebSocketGateway } from '../event-aggregation-service/web-socket-gateway';
import AuthController from './auth-controller/auth-controller';
import { StudentapiDriver } from './auth-controller/classes/studentapi-driver';
import { BrowserSessionStorage } from "./infrastructure/browser/browser-session-storage";
import { TokenBasedSessionFactory } from './domain/factories/token-based-session-factory';
import { TokenBasedSessionRepositoryFactory } from './domain/factories/token-based-session-repository-factory';
import { TokenBasedSessionService } from './domain/services/token-based-session-service';
import { RollbarIdentitySubscriber } from "./domain/subscribers/rollbar-identity-subscriber";
import { Credentials } from './domain/entities/credentials';
import { TokenService } from './domain/services/token-service';

// let webSocketGateway = new SocketIOGateway(`${config.wssEndpoint}`);
let webSocketGateway = new WebSocketGateway(`${config.wssEndpoint}`);
let eventAggregationService = new EventAggregationService(webSocketGateway);
let tokenService = new TokenService(config.tokenServiceBaseUrl);
let tokenSessionFactory = new TokenBasedSessionFactory(eventAggregationService, tokenService);
const tokenBasedSessionRepositoryFactory = new TokenBasedSessionRepositoryFactory(eventAggregationService, tokenSessionFactory, config);
let tokenRepository = tokenBasedSessionRepositoryFactory.createByBrowserNavigator(navigator);
let sessionService = new TokenBasedSessionService(tokenRepository, eventAggregationService, tokenService);
let studentApiDriver = new StudentapiDriver(config.studentApiBaseUrl, FetchWrapper);
let authController = new AuthController(sessionService, studentApiDriver, eventAggregationService, new BrowserSessionStorage());
const startTime = new Date();

webSocketGateway.EventAggregationService = eventAggregationService;

/**
 * Make objects global
 */
window['AuthController'] = authController;
window['EventAggregationService'] = eventAggregationService;
window['SessionService'] = sessionService;
window['SessionFactory'] = tokenSessionFactory;

async function setSessionFromCredentials(credentials: Credentials): Promise<void> {

	const session = tokenSessionFactory.create(credentials);
	await sessionService.setSession(session);
	removeRefreshTokenFromURL();
}

function setSessionBasedOnRefreshTokenInUrl(): void {
	const refreshToken = (new URL(window.location as any)).searchParams.get('refreshToken');
	if (null !== refreshToken) {

		setSessionFromCredentials(new Credentials(null, refreshToken));
	}
}

function removeRefreshTokenFromURL(): void {

	const url = new URL(location.href);
	url.searchParams.delete('refreshToken');
	window.history.pushState({}, document.title, url.toString());
}

/**
 * Create session if refreshToken available but only do it when sessionService is ready
 */
// tslint:disable-next-line:no-shadowed-variable
eventAggregationService.subscribeToSessionServiceReadyEvent(new CallbackSubscriber(async (sessionService: ISessionService) => {

	if (document.readyState === 'complete') {
		setSessionBasedOnRefreshTokenInUrl();
	} else {
		document.addEventListener('readystatechange', (event) => {
			const target = event.target as any;
			if (target.readyState === 'complete') {
				setSessionBasedOnRefreshTokenInUrl();
			}
		});
	}

	webSocketGateway.initialize(sessionService);
}));

let readyTime = null;

function trackSessionServiceReadyEvent(): void {
	if (readyTime === null) {
		return;
	}
	window['snowplow'](
		'trackStructEvent',
		'SessionService',
		'initializing',
		'completed',
		null,
		readyTime.getTime() - startTime.getTime(),
	);
}

// Track if SessionService initialises correctly
eventAggregationService.subscribeToSessionServiceReadyEvent(new CallbackSubscriber(() => {
	if (typeof window['snowplow'] === 'undefined') {
		console.warn('Snowplow not ready on window');
		return null;
	}

	readyTime = new Date();

	// tslint:disable-next-line:only-arrow-functions
	window['snowplow'](function () {
		if (Object.keys(this).join('|').includes('cf7_')) {
			trackSessionServiceReadyEvent();
		}

		document.addEventListener('DataTrackerReady', () => {
			trackSessionServiceReadyEvent();
		});
	});

}));

/**
 * Subscribe for login event from AuthenticationService and create session from it
 */

document.addEventListener('student_logged_in', (e: any) => {
	const credentials = new Credentials(e?.detail?.accessToken, e?.detail?.refreshToken);
	setSessionFromCredentials(credentials);
});

/**
 * Subscribe for changes to the session credentials and create a session from it across tabs
 **/
window.addEventListener('storage', async function (e) {

	if (e.key === "SessionService/credentials") {

		try {

			const storedData = JSON.parse(e.newValue);
			const credentialsSerialized = JSON.parse(storedData.data);
			if(!credentialsSerialized.accessToken || !credentialsSerialized.refreshToken){
				return;
			}

			const credentials = new Credentials(
				credentialsSerialized.accessToken,
				credentialsSerialized.refreshToken
			);

			const session = await sessionService.getSession();

			if (session !== null) {
				return session.setCredentials(credentials);
			}

			setSessionFromCredentials(
				credentials
			);
		} catch (e) {

			console.warn('SessionService: Couldn\'t create a session with provided credentials.');
		}
	}
});

/**
 * Track identity id with GA if logged in
 */
authController.subscribe(async (error, data) => {

	if (data.LoggedInStatus === true) {
		if (typeof window['ga'] !== 'undefined') {

			window['ga']('set', 'userId', await authController.getIdentityId());

		}
	}
});


eventAggregationService.subscribeToSessionServiceReadyEvent(new RollbarIdentitySubscriber(eventAggregationService));
/**
 * Initialize objects
 */
authController.initialize();
sessionService.initialize();
tokenRepository.initialize();
